import React, { useRef, useEffect, useState } from 'react';
import { render } from 'react-dom'

import EmailEditor from 'react-email-editor';
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST, API_UPLOAD } from "../../services/api.service";
import { copyToClipboard } from "../../services/Common";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Divider } from '@material-ui/core';
import InfoBox from "../../components/InfoIcon/InfoIcon";



const EmailTemplate = (props) => {
  const emailEditorRef = useRef(null);
  const { classes, user, cid } = props;
  const [customFields, setCustomFields] = useState([]);

  let frmVal = {
    type: 'email',
    subject: null,
    body: null,
    name: null,
    admin: user.admin,
    isActive: true,
    createdBy: user.username,
    createdOn: new Date()
  };
  const [formValues, setFormValues] = useState(frmVal);
  const [count, setCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [fileControls, setFileControls] = useState([]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const exportHtml = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;
      console.log('exportHtml', html);

      const tid = props.match.params.id;

      if (user && user.admin) {


        let frmValues = {
          ...formValues,
          body: html,
          design
        }

        let data = new FormData();
        debugger;
        for (let index = 0; index < files.length; index++) {
          const element = files[index];
          data.append(
            element.id,
            element.selectedFile,
            element.FileName

          );
        }

        if (tid) {
          delete frmValues["_id"];
          data.append("body", JSON.stringify(frmValues));
          data.append("admin", user && user.admin);
          data.append("withFile", 1);
          data.append("root", "Templates");
          data.append("querydata", JSON.stringify({ _id: tid }));

          let registerRequest = await API_UPLOAD("action/update", data);
        }
        else {
          data.append("withFile", 1);
          data.append("body", JSON.stringify(frmValues));
          data.append("admin", user && user.admin);
          data.append("root", "Templates");

          let registerRequest = await API_UPLOAD("action/addRecord", data);
        }


        props.history.push("/admin/Templates");
      }
    });

  };

  const copyTo = (e, item) => {
    console.log(e);
    //let str = e.target.innerHTML
    copyToClipboard('${' + item.field_name + '}', e.target);
    //alert(str);
  };

  const onLoad = () => {
    try {
      //console.log("e", e)
      // you can load your template here;
      debugger;
      const tid = props.match.params.id;
      if (tid && formValues && formValues.design) {
        const templateJson = formValues && formValues.design;
        emailEditorRef.current.editor.loadDesign(templateJson);
      }
      fnResizeFrame();

    }
    catch (err) {
      console.log(err);
    }
  };

  const fnResizeFrame = () => {
    setTimeout(function () {
      try {
        document.querySelector(".editor iframe").style.minWidth = '500px';
      }
      catch (err) {
        console.log(err);
        fnResizeFrame();
      }

    }, 2000);
  }

  const pageLoad = async () => {
    let registerRequest;
    try {

      if (user && user.admin) {

        registerRequest = await API_POST("action/getdata",
          {
            root: 'prospect_fields',
            con: { admin: user && user.admin },
            cols: {}
          });

        if (registerRequest && registerRequest.status == 200) {

          setCustomFields(registerRequest && registerRequest.data);

        }
      }
    }
    catch (error) {
      console.log(error)
      //registerRequest = response;
    }
  };

  useEffect(() => {
    if (user) {
      pageLoad();

      const tid = props.match.params.id;
      if (tid) {
        loadTemplate(tid);
      }
    }
  }, [user]);

  const loadTemplate = async (tid) => {
    let registerRequest = await API_POST("action/getdata", {
      root: "Templates",
      con: { admin: user && user.admin, _id: tid },
      cols: {},
    });

    if (registerRequest && registerRequest.status == 200) {
      setFormValues(registerRequest.data[0]);
      console.log(registerRequest.data[0]);
      // setTimeout(function(){
      //   onLoad();
      // }, 1000);
      let frmVal = registerRequest.data[0];
      const tid = props.match.params.id;
      if (tid && frmVal && frmVal.design) {
        const templateJson = frmVal && frmVal.design;
        emailEditorRef &&
          emailEditorRef.current &&
          emailEditorRef.current.editor &&
          emailEditorRef.current.editor.loadDesign(templateJson);
      }
    }

    //setFormValues(formValue);
  }
  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  useEffect(() => { }, [count]);

  const handleUpload = async (evt) => {


    let f = files;

    let selectedIndex = -1;
    for (let index = 0; index < f.length; index++) {
      const element = f[index];
      if (element.id == evt.target.id) {
        selectedIndex = index;
        return;
      }
    }

    if (selectedIndex > -1) {
      //update
      f[selectedIndex].FileName = evt.target.files[0].name;
      f[selectedIndex].selectedFile = evt.target.files[0];
    }
    else {
      //add
      let file = {
        id: evt.target.id,
        FileName: evt.target.files[0].name,
        selectedFile: evt.target.files[0]
      }
      f.push(file);
    }
    setFiles(f);
  };

  const addFile = async (e) => {

    let fc = fileControls;
    fc.push(<input
      type="file"
      name={"file_" + fileControls.length + 1}
      id={"file_" + fileControls.length + 1}
      key={"file_" + fileControls.length + 1}
      onChange={(e) => {
        handleUpload(e)
      }}
    />)
    setFileControls(fc);
    forceUpdate();
  }


  return (
    <GridContainer>
      <Snackbar
        place="tr"
        // color={this.state.notify.color}
        // message={this.state.notify.message}
        // open={this.state.notify.open}
        // closeNotification={() =>
        //   this.setState({
        //     notify: {
        //       open: false,
        //     },
        //   })
        // }
        close
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="transparent">
            <h3>Email Template
              <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Templates.EmailTemplates.AddEmailTamplate'} />
              </GridItem>
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <div className="editor">
                  <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    style={{ minWidth: '500px' }}
                    DisplayMode="web"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <GridItem xs={12} sm={12} md={12}>

                  <CustomInput
                    labelText="Name *"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      required: true,
                      value: formValues && formValues.name,
                      name: "name",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>

                  <CustomInput
                    labelText="Subject *"
                    id="subject"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      required: true,
                      value: formValues && formValues.subject,
                      name: "subject",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <ul>
                    {

                      formValues.Files && formValues.Files.map((item, index) => {
                        return <li><a target="_blank" href={item.file}>{item.filename}</a></li>
                      })

                    }
                  </ul>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {
                    fileControls
                  }
                </GridItem>
                <GenerateField
                  value={formValues && formValues.isActive}
                  onChange={handleChange}
                  id={"isActive"}
                  label={"Active"}
                  type="checkbox"
                />
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    variant="transparent"
                    color="info"
                    size="sm"
                    onClick={exportHtml}
                  >
                    Save Template
                  </Button>
                  <Button
                    variant="transparent"
                    color="info"
                    size="sm"
                    onClick={addFile}
                  >
                    Add Attachment
                  </Button>
                </GridItem>

                <Divider />

                <GridItem xs={12} sm={12} md={12} style={{ height: '400px', overflow: 'auto' }}>
                  {
                    customFields.map((item, index) => {
                      return <Button
                        variant="transparent"
                        size="sm"
                        onClick={(e) => copyTo(e,item)}
                      >
                        {item.field_name}
                      </Button>
                    })
                  }
                </GridItem>
              </GridItem>


            </GridContainer>



          </CardBody>
          <CardFooter>

          </CardFooter>
        </Card>
      </GridItem>


    </GridContainer>



  );
};
export default withStyles(dashboardStyle)(EmailTemplate);
class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
