import React from "react";

export const getQueryParams = (params, url) => {
  let href = url;
  //this expression is to get the query strings
  let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export const maskNumber = (phone) => {
  var maskedNumber = phone.replace(/\d(?=\d{2})/g, "X");
  return maskedNumber;
};

export const getToken = () => {
  //return localStorage.getItem("token");
  return getCookie("token");
}

export const setToken = (token) => {
  debugger
  return setCookie("token", token, 1)

  //return localStorage.setItem("token", token);
}

export const removeToken = () => {
  //return localStorage.removeItem("token");
  return setCookie("token", "", -1);
}

export const copyToClipboard = (str, element) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (element) {
    const originalText = element.textContent;
    element.textContent = originalText +' Copied!';
    setTimeout(() => {
      element.textContent = originalText;
    }, 2000); // 2 seconds delay
  }
};

export const getProduct = () => {
  return 1;
}

export function setCookie(cname, cvalue, exdays,object=false) {
  var d = new Date();
  if(object) cvalue = JSON.stringify(cvalue);
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var InExpires = "domain=.betyphon.in;expires=" + d.toUTCString();
  var ComExpires = "domain=.betyphon.com;expires=" + d.toUTCString();
  var LocalExpires = "domain=localhost;expires=" + d.toUTCString();

  document.cookie = cname + "=" + cvalue + ";" + InExpires + ";path=/";
  document.cookie = cname + "=" + cvalue + ";" + ComExpires + ";path=/";
  document.cookie = cname + "=" + cvalue + ";" + LocalExpires + ";path=/";
}



export function getCookie(cname,object=false) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);

  var ca = decodedCookie.split(';');
  //console.log("coooo",ca)
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const formatFieldValue = (value) => {
  if (value === null || value === undefined || value === "") return ""; // Handle null, undefined, or empty values
  
  // Check for Boolean values
  if (typeof value === "boolean") {
    return value ? "Yes" : "No"; // Return "Yes" for true, "No" for false
  }

  // Check for strictly numeric strings or numbers
  if (!isNaN(value) && !isValidDateTime(value)) {
    return parseInt(value, 10); // Convert to integer
  }

  // Check for DateTime
  if (isValidDateTime(value)) {
    return new Date(value).toLocaleString(); // Format as datetime
  }

  // Check for Email
  if (isValidEmail(value)) {
    //return <a href={`mailto:${value}`}>{value}</a>; // Hyperlink email
    return value; // Hyperlink email
  }

  // Check for URL
  if (isValidURL(value)) {
    return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>; // Hyperlink URL
  }

  // Check for Phone Number
  if (isValidPhoneNumber(value)) {
    return <a href={`tel:${value}`}>{value}</a>; // Hyperlink phone number
  }

  // Default: Return the value as-is
  return value;
};

// Validation helper methods
const isValidDateTime = (value) => {
  if (typeof value !== "string") return false; // Ensure the value is a string

  // Regex to match common date formats: YYYY-MM-DD, YYYY/MM/DD, and ISO format
  const dateRegex = /^\d{4}[-/]\d{2}[-/]\d{2}(T\d{2}:\d{2}(:\d{2}(\.\d{1,3})?)?(Z|([+-]\d{2}:\d{2})))?$/;

  if (!dateRegex.test(value)) {
    return false; // Invalid date format
  }

  const date = new Date(value);
  return !isNaN(date.getTime()); // Check if the parsed date is valid
};

const isValidEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
  return emailRegex.test(value);
};

const isValidURL = (value) => {
  try {
    new URL(value); // Will throw an error if invalid
    return true;
  } catch {
    return false;
  }
};

const isValidPhoneNumber = (value) => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 international phone number format
  return phoneRegex.test(value);
};