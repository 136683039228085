/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import { API_POST } from "../../services/api.service";
import Typography from '@material-ui/core/Typography';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';
import Appointment from "./Appointment";
import Followups from "./Followups";
import Card from "components/Card/Card.jsx";
import Overdue from "./overdue";

class Schedule extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      campaignList: [],
      campaignsDetails: null,
      activeTab: 0,
      cid: ""
    };
  }

  

  componentDidMount() {
    let cid = this.props.match.params.cid;
    if (cid) {
      this.setState({ cid: cid });
      this.loadCampaign(cid);
    }
  }

  loadCampaign = async (id) => {

    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { _id: id },
      });

      if (registerRequest && registerRequest.status == 200) {
        //console.log(registerRequest.data);
        if (registerRequest.data && registerRequest.data.length > 0) {
          this.setState({ campaignsDetails: registerRequest.data[0] });
        }
        //this.setState({ campaignsDetails: registerRequest.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // to stop the warning of calling setState of unmounted component

  render() {
    const { user } = this.props;
    const cid = this.props.match.params.cid;
    const { campaignsDetails } = this.state;
    console.log("campaignsDetails", campaignsDetails);
    const urlParam = new URLSearchParams(window.location.search);
    const overduetab = urlParam.get("overduetab");
    urlParam.set("overduetab", "0");
    urlParam.delete("overduetab");

    return (
      <>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <Typography gutterBottom variant="h5" component="h2">
              {campaignsDetails && campaignsDetails.name
                ? campaignsDetails.name
                : ""}
            </Typography>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="info"
              selectedValue={overduetab? 1 : 0}
              tabs={[
                {
                  tabName: "FollowUps",
                  tabContent: (
                    <ErrorBoundary name="FollowUps">                      
                      <Followups
                        user={user}
                        cid={this.state.cid}
                        history={this.props.history}
                      />
                    </ErrorBoundary>
                  ),
                },
                {
                  tabName: "OverDue",
                  tabContent: (
                    <ErrorBoundary name="FollowUps">                      
                      <Overdue
                        user={user}
                        cid={this.state.cid}
                        history={this.props.history}
                      />
                    </ErrorBoundary>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default withStyles(dashboardStyle)(Schedule);
