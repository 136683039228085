import React, { useRef, useEffect, useState } from 'react';
import { render } from 'react-dom'


import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import { copyToClipboard } from "../../services/Common";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Divider } from '@material-ui/core';
import InfoBox from "../../components/InfoIcon/InfoIcon";



const SMSTemplate = (props) => {

  const { classes, user, cid } = props;
  const [customFields, setCustomFields] = useState([]);
  const [APISetup, setAPISetup] = useState([]);

  let frmVal = {
    type: 'sms',
    body: null,
    name: null,
    setup_id: null,
    admin: user.admin,
    isActive: true,
    createdBy: user.username,
    createdOn: new Date()
  };
  const [formValues, setFormValues] = useState(frmVal);
  const [count, setCount] = useState(0);

  const exportHtml = async () => {


    const tid = props.match.params.id;

    if (user && user.admin) {

      let frmValues = {
        ...formValues
      }

      if (tid) {
        delete frmValues["_id"];
        let registerRequest = await API_POST("action/update", {
          root: "Templates",
          body: frmValues,
          querydata: { _id: tid },
        });
      }
      else {
        let registerRequest = await API_POST("action/addRecord", {
          root: "Templates",
          body: frmValues,
        });
      }


      props.history.push("/admin/Templates?tab=1");
    }

  };

  const copyTo = (e, item) => {
    console.log(e);
    //let str = e.target.innerHTML
    copyToClipboard('${' + item.field_name + '}', e.target);
    let updatedbody = formValues;
    updatedbody.body = updatedbody.body + ' ${' + item.field_name + '}';
    console.log(updatedbody)
    handleChange({target:{value:updatedbody.body,type:"textarea",name:"body"}});
    //alert(str);
  };

  const onLoad = () => {
    try {
      //console.log("e", e)
      // you can load your template here;
      debugger;
      const tid = props.match.params.id;
      if (tid && formValues && formValues.design) {
        const templateJson = formValues && formValues.design;

      }


    }
    catch (err) {
      console.log(err);
    }
  };



  const pageLoad = async () => {
    let registerRequest;
    try {

      if (user && user.admin) {

        registerRequest = await API_POST("action/getdata",
          {
            root: 'prospect_fields',
            con: { admin: user && user.admin },
            cols: {}
          });

        if (registerRequest && registerRequest.status == 200) {

          setCustomFields(registerRequest && registerRequest.data);

        }

        registerRequest = await API_POST("action/getdata", {
          root: "CallingSetup",
          con: { admin: user && user.admin, IsActive: true, type: 'SMSAPI' },
          cols: {},
        });

        if (registerRequest && registerRequest.status == 200) {
          let _options = [];
          registerRequest.data.forEach((element) => {
            _options.push({
              key: element._id,
              value: element.name,
            });
          });
          setAPISetup(_options);
        }

      }
    }
    catch (error) {
      console.log(error)
      //registerRequest = response;
    }
  };

  useEffect(() => {
    if (user) {
      pageLoad();

      const tid = props.match.params.id;
      if (tid) {
        loadTemplate(tid);
      }
    }
  }, [user]);

  const loadTemplate = async (tid) => {
    let registerRequest = await API_POST("action/getdata", {
      root: "Templates",
      con: { admin: user && user.admin, _id: tid },
      cols: {},
    });

    if (registerRequest && registerRequest.status == 200) {
      setFormValues(registerRequest.data[0]);
      console.log(registerRequest.data[0]);



    }

    //setFormValues(formValue);
  }
  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  useEffect(() => { }, [count]);



  return (
    <GridContainer>
      <Snackbar
        place="tr"
        // color={this.state.notify.color}
        // message={this.state.notify.message}
        // open={this.state.notify.open}
        // closeNotification={() =>
        //   this.setState({
        //     notify: {
        //       open: false,
        //     },
        //   })
        // }
        close
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="transparent">
            <h3>SMS Template
              <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Templates.SmsTemplates.AddSmsTamplate'} />
              </GridItem>
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <div className="editor">
                  <CustomInput
                    labelText="Message Body*"
                    id="body"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      required: true,
                      defaultValue: formValues && formValues.body,
                      name: "body",
                      type: "textarea",
                      onChange: handleChange,
                      rows: 20,
                      rowsMax: 50
                    }}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <GridItem xs={12} sm={12} md={12}>

                  <CustomInput
                    labelText="Name *"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      required: true,
                      value: formValues && formValues.name,
                      name: "name",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                <GenerateField
                  value={formValues && formValues.setup_id}
                  onChange={handleChange}
                  id={"setup_id"}
                  label={"WhatsApp Setup"}
                  keyValuePair={APISetup}
                />
                <GenerateField
                  value={formValues && formValues.isActive}
                  onChange={handleChange}
                  id={"isActive"}
                  label={"Active"}
                  type="checkbox"
                />
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    variant="transparent"
                    color="info"
                    size="sm"

                    onClick={exportHtml}
                  >
                    Save Template
                  </Button>
                </GridItem>

                <Divider />

                <GridItem xs={12} sm={12} md={12} style={{ height: '400px', overflow: 'auto' }}>
                  {
                    customFields.map((item, index) => {
                      return <Button
                        variant="transparent"
                        size="sm"
                        onClick={(e) => copyTo(e,item)}
                      >
                        {item.display_Name}
                      </Button>
                    })
                  }
                </GridItem>
              </GridItem>


            </GridContainer>



          </CardBody>
          <CardFooter>

          </CardFooter>
        </Card>
      </GridItem>


    </GridContainer>



  );
};
export default withStyles(dashboardStyle)(SMSTemplate);
class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
