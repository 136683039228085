import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
//import axios from 'axios';
import { API_GET, API_POST } from "../../services/api.service";
import { Link } from "react-router-dom";
//import Button from "components/CustomButtons/Button.jsx";
import { Button, ButtonGroup } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import InfoBox from "../../components/InfoIcon/InfoIcon";


const { REACT_APP_SERVER_URL } = process.env;

function FilesList(props) {
  const { user, cid, campaignsDetails } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);

  const countPerPage = 10;

  const StartProcess = async (row) => {
    let data = {
      filePath: row.ServerPath,
    };
    let fileupload = await API_POST(`action/uploadFileHeaders`, data);
    if (fileupload.success && fileupload.data && fileupload.data.headers && fileupload.data.headers.length > 0) {
      let obj = {
        headers: fileupload.data.headers[0].data[0],
        prospectFields: fileupload.data.prospectFields,
      };
      props.RebindHeader(obj, row);
    }
  };

  const ReProcess = async (row) => {
    let data = {
      fid: row._id,
    };
    await API_GET(`action/StartProcessingRecords?fid=${row._id}`);


  };
  const onDownload = async (row) => {
    let href = `//${REACT_APP_SERVER_URL}/downloads/downloadAWSFile?fid=${row._id}&endpoint=filesCollection`;

    window.open(href, "_blank")
  };
  const ViewHistory = async (row) => {
    let href = `/admin/ProspectHistory?fid=${row._id}`;

    window.open(href, "_self")
  };

  const getFileList = async () => {
    try {
      let con = { admin: user && user.admin, cid };

      let res = await API_GET(
        `action/getFilesList?page=${page}&per_page=${countPerPage}&delay=1`,
        null,
        {
          query: con,
        }
      );
      debugger;
      setData(res && res.data);
    }
    catch (e) {

    }
  };

  const updateFileCollection = async (element, Type) => {
    let registerRequest;
    let IsActiveFile = Type;

    registerRequest = await API_POST("action/upsert", {
      root: "filesCollection",
      body: { IsActiveFile },
      querydata: { admin: user.admin, _id: element._id },
    });
    if (registerRequest && registerRequest.status == 200) {
      getFileList();
    }
  };

  const handleAction = async (e, element, Type) => {
    let registerRequest;
    let IsActive = false;
    IsActive = Type == "Active" ? true : false;

    registerRequest = await API_POST("action/updateMany", {
      root: "prospects",
      body: { IsActive },
      querydata: { admin: user.admin, cid, fid: element._id },
    });
    if (registerRequest && registerRequest.status == 200) {

      updateFileCollection(element, IsActive);
    }
  };



  const columns = [
    {
      name: "File Name",
      width: "200px",
      cell: (row) => (
        <span>{row.filename}</span>
      ),
    },
    {
      name: "Created On",
      width: "120px",
      cell: (row) => (
        <span>{dayjs(row.createdOn).format("DD/MM/YYYY hh:mm")}</span>
      ),
    },
    {
      name: "Total Records",
      //selector: "TotalRecordsInExcel",
      width: "100px",
      cell: (row) => (
        <span>{row.TotalRecordsInExcel}</span>
      ),
    },
    {
      name: "Inserted Records",
      //selector: "InsertedRecords",
      width: "100px",
      cell: (row) => (
        <span>{row.InsertedRecords}</span>
      ),
    },
    {
      name: "Updated Records",
      //selector: "UpdatedRecords",
      width: "100px",
      cell: (row) => (
        <span>{row.UpdatedRecords}</span>
      ),
    },
    {
      name: "Failed Records",
      //selector: "ErrorRecords",
      width: "100px",
      cell: (row) => (
        <span>{row.ErrorRecords}</span>
      ),
    },
    {
      name: "Error Message",
      //selector: "errorMessage",
      width: "100px",
      cell: (row) => (
        <span>{row.errorMessage}</span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <ButtonGroup
            size="small"
            fullWidth="true"
            color="info"
            aria-label="extra-small outlined button group"
          >
            {(row.IsExcelExport || row.IsFileUploaded) &&
              <Button title="download" onClick={(e) => {
                onDownload(row);
              }}>
                <GetAppOutlinedIcon />
              </Button>
            }

            {row.TotalRecordsInExcel > row.TotalRecords &&
              <Button title="Re-Mapping" onClick={(e) => {
                StartProcess(row);
              }}>
                <CompareArrowsIcon />
              </Button>
            }
            {row.TotalRecordsInExcel > row.TotalRecords &&
              <Button title="Start Process" onClick={(e) => {
                ReProcess(row);
              }}>
                <AutorenewIcon />
              </Button>
            }
            {row.TotalRecordsInExcel <= row.TotalRecords &&
              <Button title="View Prospects" onClick={(e) => {
                ViewHistory(row);
              }}>
                <ViewHeadlineIcon />
              </Button>
            }
            {/* <Link to={`/admin/ProspectHistory?fid=${row._id}`}>
              View Prospects
            </Link> */}



          </ButtonGroup>


          {/* {row.IsExcelExport ? (
            <a
              href={`//${REACT_APP_SERVER_URL}/downloads/downloadAWSFile?fid=${row._id
                }&endpoint=filesCollection`}
              target="_blank"
            >
              Download
            </a>
          ) : (
            <>
              {row.TotalRecordsInExcel > row.TotalRecords ? (
                <>
                  <a
                    onClick={(e) => {
                      StartProcess(row);
                    }}
                  >
                    Re-Mapping
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    onClick={(e) => {
                      ReProcess(row);
                    }}
                  >
                    Re-Processing
                  </a>
                </>

              ) : (
                <Link to={`/admin/ProspectHistory?fid=${row._id}`}>
                  View Prospects
                </Link>
              )}
              &nbsp;|&nbsp;
              {row.IsFileUploaded != false ? (
                <a
                  href={`//${REACT_APP_SERVER_URL}/downloads/downloadAWSFile?fid=${row._id
                    }&endpoint=filesCollection`}
                  target="_blank"
                >
                  Download
                </a>
              ) : (
                "Uploading..."
              )}
            </>
          )} */}
        </>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <>
          {!row.IsExcelExport && campaignsDetails && campaignsDetails.ActiveInactiveProspect && (
            <>
              {!row.IsActiveFile && (
                <Button
                  size="sm"
                  title="Filter"
                  color="primary"
                  onClick={(e) => {
                    handleAction(e, row, "Active");
                  }}
                >
                  Active
                </Button>
              )}

              {row.IsActiveFile && (
                <Button
                  size="sm"
                  title="Filter"
                  color="danger"
                  onClick={(e) => {
                    handleAction(e, row, "Inactive");
                  }}
                >
                  Deactive
                </Button>
              )}
            </>
          )}
        </>
      ),
      width: "100px",
    },
  ];



  useEffect(() => {
    getFileList();
    const interval = setInterval(() => {
      console.log("auto reload")
      getFileList();
    }, 3000);

    return () => clearInterval(interval);

  }, [page]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>File Logs
            <GridItem style={{ float: "right" }}>
              <InfoBox name={'SystemLogs.Files'} />
            </GridItem>
          </h3>
        </GridItem>
     
        <GridItem xs={12} sm={12} md={12}>
          {/* {JSON.stringify(data)} */}
          <DataTable
            columns={columns}
            data={data && data.data}
            paginationTotalRows={data && data.total}
            paginationPerPage={countPerPage}
            highlightOnHover
            pagination
            paginationServer
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
          />
        </GridItem>
      </GridContainer>

    </>
  );
}

export default FilesList;
