import React, {useEffect, useRef, useState} from "react";
import PropTypes, { any } from "prop-types";
import serialize from "form-serialize";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import CommonJson from "../common/CommonJson";
import ViewTags from "../Forms/ViewTags";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function EditProspect(props) {
  const { classes, user, baseProspects, pid , showEdit } = props;
  const editRef= useRef(null);
  let frmVal = {
    country: "IN",
    source: "",
    assignTo: "",
  };

  const [formValues, setFormValues] = useState(baseProspects);
  const [customFields, setCustomFields] = useState([]);
  const [campaignMembers, setCampaignMembers] = useState(null);
  const [campaignOption, setCampaignOption] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedTag, setSelectedTags] = useState(null);

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const handleClose = () => {
    setOpen(false);
  };



  const campaignload = async () =>{

    try{

      let requestAPI = await API_GET("register/GetAllMembersByUserId?id="+user.admin_id);
  
      let isUseraddedInCampaign = requestAPI.data.teams_membership;
      let campoptions=[];
      isUseraddedInCampaign = isUseraddedInCampaign.map((v)=>{ campoptions.push({key:v.username,value:v.username}) });
      console.log(isUseraddedInCampaign)
      setCampaignOption(campoptions);

    }catch(e){
       console.log(e)

    }

  }
  

  const loadCustomFields = async (e) => {
    if (!user) {
      return;
    }
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "prospect_fields",
        con: { admin: user && user.admin },
      });

      if (registerRequest && registerRequest.status == 200) {

        // ---------------Remove irrelavent col.-------------//
        let validKeys = CommonJson.ProspectValidKeys;
        for (var i in registerRequest.data) {
          if (validKeys.indexOf(registerRequest.data[i].field_name) > -1) {
            registerRequest.data.splice(i, 1);
          }
        }
        setCustomFields(registerRequest && registerRequest.data);
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  const handleChange = async (e,multi=false) => {
    let formValue = formValues;

    console.log(e);
    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    }if (multi) {
      formValue[e.target.name] = e.target.value && e.target.value.length ? e.target.value.toString() : '';
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }


    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleCancelProspect = (e) => {
    e.preventDefault();
    document.getElementsByName("frmProspect")[0].reset();
    showEdit(false);
  };

  const onSelectTag = async (item) => {
    console.log("tag", item);
    setSelectedTags(item);
  };
  const handleAddField = async (e) => {
    e.preventDefault();
    const { classes, user, baseProspects, pid } = props;
    debugger;
    let frm = serialize(document.getElementsByName("frmProspect")[0], {
      hash: true,
      empty: true,
      disabled: true,
    });
    console.log(formValues);
    let formValue = frm;
    //formValue['admin'] = user && user.admin;
    formValue["updatedBy"] = user && user.username;
    formValue["updatedOn"] = new Date();
    formValue['tags'] = selectedTag;
    //formValue['cid'] = props.match.params.cid;

    //alert(formValue['assignTo'])
    if(!formValue['assignTo']){
      delete formValue['assignTo'];
      //formValue['assignTo'] = campaignMembers;
    }


    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "prospects",
        body: formValue,
        querydata: { _id: pid },
      });
      // registerRequest = await API_POST("action/addRecord",
      //   {
      //     root: 'prospects',
      //     body: formValue
      //   });
      console.log(registerRequest);
      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        setNotify({
          open: true,
          message: "Prospect Saved.",
          color: "success",
        });

        showEdit(false)
       
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  const handleEditField = async (e) => {
    e.preventDefault();
    debugger;
    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.admin;
    formValue["updatedBy"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "prospects",
        body: formValue,
        querydata: { _id },
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadCustomFields(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editSubRecord = async (item) => {
    setMode("Edit");
    setFormValues(item);
    setOpen(true);
  };

  useEffect(() => {
    debugger;
    if (user && customFields.length == 0) {
      loadCustomFields();
      campaignload();
    }
    editRef  && editRef.current.scrollIntoView({ behavior: "smooth"})
  }, [user]);

  const selectOption = async (item) => {
    
    if(!item){
      return [{key:'No Option', value: 'No Option'}];
    }
    let options = await item.split(',').map((v)=> {return  {key:v, value: v} });
    console.log(options);
    return options;
  };
  const selectvalues= async (item) =>{

  }



  return (
    <form name="frmProspect"  ref={editRef} onSubmit={handleAddField}>
      <GridContainer>
        <Snackbar
          place="tr"
          color={notify.color}
          message={notify.message}
          open={notify.open}
          closeNotification={() => setNotify({ open: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="white">
              <h3>Change Prospect</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {customFields.map((item, index) => {

                  let type=item.field_type;
                  let option=item.selectOptions;
                  let value=formValues && formValues[item.field_name];
                  console.log("alllllll",value)

                  if(item.display_Name == 'Repeat'){
                    return null;
                  }

                  // 2022-06-04T23:13
                  if(type=='datetime'){
                    type='date';
                    let d=formValues && new Date(formValues[item.field_name]);
                    let datestring = ( d.getFullYear()) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
                        ("0"+(d.getDate())).slice(-2);
                    value=datestring;
                  }



                  return (item.IsEditable && <>
                    
                    {
                      type == 'select' ? <>
                    <GridItem xs={12} sm={6} md={4}>
                      <CustomInput
                         labelText={
                          item.isRequired 
                            ? <span>{item.display_Name} <span style={{ color: 'red' }}>*</span></span> 
                            : item.display_Name
                        }
                        id={item.field_name}
                        formControlProps={{
                          fullWidth: true,
                        }}
                       
                        labelProps={{ shrink: true }}
                        inputProps={{
                          required: item.isRequired,
                          name: item.field_name,
                          options:option.split(',').map((v)=> {return  {key:v, value: v} }),
                          type:  type,
                          value:value,
                         
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>
                      </> : type == 'multiselect' ? <>
                    <GridItem xs={12} sm={6} md={4}>
                      <CustomInput
                         labelText={
                          item.isRequired 
                            ? <span>{item.display_Name} <span style={{ color: 'red' }}>*</span></span> 
                            : item.display_Name
                        }
                        id={item.field_name}
                        formControlProps={{
                          fullWidth: true,
                        }}
                       
                        labelProps={{ shrink: true }}
                        inputProps={{
                          required: item.isRequired,
                          name: item.field_name,
                          options:option.split(',').map((v)=> {return  {key:v, value: v} }),
                          type:  'checkbox_list',
                          value: value ? value.split(',') : [],
                          defaultValue: value ? value.split(',') : [],
                         
                          onChange: (e) => handleChange(e,true) ,
                        }}
                      />
                    </GridItem>
                      </> :  <>
                      <GridItem xs={12} sm={6} md={4}>
                      <CustomInput
                        labelText={
                          item.isRequired 
                            ? <span>{item.display_Name} <span style={{ color: 'red' }}>*</span></span> 
                            : item.display_Name
                        }
                        id={item.field_name}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelProps={{
                          shrink:
                            ["radio", "checkbox"].indexOf(item.field_type) > -1
                              ? false
                              : true,
                        }}
                        customOptions={item.field_options}
                        inputProps={{
                          required: item.isRequired,
                          name: item.field_name,
                          value:value,
                          type:  type,
                          defaultValue:value,
                          disabled:item.field_name == 'phone' ? true : false,

                          onChange:handleChange,
                        }}
                      />

                    </GridItem>
                      </>

                    }
                    </>
                    
                  );
                })}

              
                  
                     <GridItem xs={12} sm={6} md={4}>
                       <ViewTags
                         user={user}
                         onSelectTag={(item) => onSelectTag(item)}
                         tags={baseProspects && baseProspects.tags}
                        
                       />

                    </GridItem>
                  
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="success" type="submit">
                Save
              </Button>
              <Button onClick={handleCancelProspect}>Cancel</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <div></div>
    </form>
  );
}

EditProspect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(EditProspect);
