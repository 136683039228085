import { API_GET, API_POST } from "../../services/api.service";
import { getCookie } from "../../services/Common";

export const getMappedTeamData = (userEmail) => {
  try {
    let obj = JSON.parse(localStorage.getItem('teamMembers'));
    //let obj = JSON.parse(getCookie("teamMembers"));
    return obj[userEmail].first_name;
  } catch (e) {
    return "";
  }
};

export const getConfigurationByName = async (user,name) => {
  try {
    let registerRequest = await API_POST("action/getdata", {
      root: "Configuration",
      con: { configName: "basic", admin: user && user.admin },
      cols: {},
    });
    if (
      registerRequest &&
      registerRequest.status == 200 &&
      registerRequest.data &&
      registerRequest.data.length > 0
    ) {
      return registerRequest.data[0][name]; 
    }
  } catch (e) {
    return "";
  }
};

export const getConfiguration = async (user) =>{
  try {
    let registerRequest = await API_POST("action/getdata", {
      root: "Configuration",
      con: { configName: "basic", admin: user && user.admin },
      cols: {},
    });
    if (
      registerRequest &&
      registerRequest.status == 200 &&
      registerRequest.data &&
      registerRequest.data.length > 0
    ) {
      return registerRequest.data[0]; 
    }
  } catch (e) {
    return {};
  }
}

export const getManager = (userEmail) => {
  try {
    let obj = JSON.parse(getCookie("teamMembers"));
    const managers = Object.entries(obj)
    .filter(([email, info]) => info.role === "manager")
    .map(([email, info]) => ({ email, ...info }));
    return managers;
  } catch (e) {
    console.log(e);
    return [];
  }
};


export const getManagerTeam = async (userid) =>{

    let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${userid.admin_id}`);
    if (userInfo.success) {
      let teamList = {};
      teamList = userInfo.data.teams_membership.filter((v)=>{ return v.reporter_id == userid.id });
      return teamList;
    } 
  
}

export const handleLastURL = () => {
  window.localStorage.setItem("LastURL", window.location.href);
};






