/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles, Box, Grid, Typography, Tab, Tabs } from "@material-ui/core";
import { CopyBlock, dracula } from "react-code-blocks";
// Custom components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import InfoBox from "components/InfoIcon/InfoIcon";
// Services
import { API_POST } from "../../services/api.service";
import { getToken } from "../../services/Common.js";
// Styles
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const { REACT_APP_SERVER_URL } = process.env;

class API extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prospectFields: [],
      activeTab: 0,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  componentDidMount() {
    const { cid } = this.props;
    if (cid) {
      this.loadCampaign(cid);
    }
  }

  loadCampaign = async (id) => {
    const { user } = this.props;
    try {
      const response = await API_POST("action/getdata", {
        root: "prospect_fields",
        con: { admin: user && user.admin },
        cols: {},
      });

      if (response && response.status === 200 && response.data) {
        this.setState({ prospectFields: response.data });
      }
    } catch (error) {
      console.error("Error loading campaign data:", error);
    }
  };

  handleTabChange = (_, value) => {
    this.setState({ activeTab: value });
  };

  renderSnackbar = () => {
    const { notify } = this.state;
    return (
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => this.setState({ notify: { open: false } })}
        close
      />
    );
  };

  renderApiFields = () => {
    const { prospectFields } = this.state;
    const { user, cid } = this.props;

    let apiFields = [];
    let phpApiFields = [];
    let getApiFields = [];

    prospectFields.forEach((field) => {
      apiFields.push(`"${field.field_name}": "<${field.field_name}>"`);
      phpApiFields.push(`"${field.field_name}" => "<${field.field_name}>"`);
      getApiFields.push(`${field.field_name}=#${field.field_name}#`);
    });

    apiFields.push(`"cid": "${cid}"`, `"admin": "${user && user.admin}"`);
    phpApiFields.push(`"cid" => "${cid}"`, `"admin" => "${user && user.admin}"`);
    getApiFields.push(`cid=${cid}`, `admin=${user && user.admin}`);

    return { apiFields, phpApiFields, getApiFields };
  };

  renderOverviewTab = () => {
    const { apiFields, getApiFields } = this.renderApiFields();
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Headers</h3>
          <Box style={{padding: "10px"}}>
          <CopyBlock
            language="json"
            text={`
Authorization: Bearer ${getToken()}`}
            codeBlock
            theme={dracula}
            showLineNumbers={false}
          />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <h3>Integration API (POST)</h3>
          <Box style={{padding: "10px"}}>
          <CopyBlock
            language="json"
            text={`
POST ${REACT_APP_SERVER_URL}/action/addProspects
Content-Type: application/json
Authorization: Bearer ${getToken()}
{ ${apiFields.join(",")} }
              `}
            codeBlock
            theme={dracula}
            showLineNumbers={false}
          />
          </Box>
        </Grid>
        <Grid item xs={12}>
        <h3>Integration API (GET)</h3>
        <Box style={{padding: "10px"}}>
          <CopyBlock
            language="json"
            text={`
GET ${REACT_APP_SERVER_URL}/action/addProspects?${getApiFields.join("&")}
Authorization: Bearer ${getToken()}
`}
            codeBlock
            theme={dracula}
            showLineNumbers={false}
          />
          </Box>
        </Grid>
      </Grid>
    );
  };

  renderPhpIntegrationTab = () => {
    const { phpApiFields, getApiFields } = this.renderApiFields();
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <h3>Integration API (POST) PHP</h3>
        <Box style={{padding: "10px"}}>
          <CopyBlock
            language="php"
            text={`<?php
$url = "${REACT_APP_SERVER_URL}/action/addProspects";
$data = array(\n${phpApiFields.join(",\n")});
$data_string = json_encode($data);
$ch = curl_init($url);
curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
curl_setopt($ch, CURLOPT_POSTFIELDS, $data_string);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    "Content-Type:application/json",
    "Authorization: Bearer ${getToken()}"
]);
$result = curl_exec($ch);
curl_close($ch);
?>`}
            codeBlock
            theme={dracula}
            showLineNumbers={false}
          />
          </Box>
        </Grid>
        <Grid item xs={12}>
        <h3>Integration API (GET) PHP</h3>
        <Box style={{padding: "10px"}}>
          <CopyBlock
            language="php"
            text={`<?php
$url = "${REACT_APP_SERVER_URL}/action/addProspects?${getApiFields.join("&")}";
$ch = curl_init($url);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    "Content-Type:application/json",
    "Authorization: Bearer ${getToken()}"
]);
$result = curl_exec($ch);
curl_close($ch);
?>`}
            codeBlock
            theme={dracula}
            showLineNumbers={false}
          />
          </Box>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { activeTab } = this.state;

    return (
      <Box>
        {this.renderSnackbar()}
        <Tabs value={activeTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Overview" />
          <Tab label="PHP Integration" />
        </Tabs>
        <Box mt={2}>
          {activeTab === 0 && this.renderOverviewTab()}
          {activeTab === 1 && this.renderPhpIntegrationTab()}
        </Box>
      </Box>
    );
  }
}

API.propTypes = {
  cid: PropTypes.string,
  user: PropTypes.shape({
    admin: PropTypes.string,
  }),
};

export default withStyles(dashboardStyle)(API);