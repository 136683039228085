import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';

// Styles for the component
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2px 10px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

// Map event types to icons
const typeIcons = {
    eat: <FastfoodIcon />,
    code: <LaptopMacIcon />,
    sleep: <HotelIcon />,
    repeat: <RepeatIcon />,
};

// Main CustomizedTimeline component
const CustomizedTimeline = ({ data }) => {
    const classes = useStyles();

    // Safely sort data by `createdDate` in descending order
    const sortedData = Array.isArray(data)
        ? [...data].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        : [];
    {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Eat</TimelineContent>
      </TimelineItem> */}
    return (
        <Timeline>
            {sortedData.map((item, index) => (
                <TimelineItem key={index} style={{minHeight:'45px'}} >
                    {/* Opposite content for timestamp */}
                    <TimelineOppositeContent style={{ width: '70px',float:'left' , padding:0 }}>
                        <Typography color="textSecondary" style={{ fontSize: '0.75rem' }}>{new Date(item.createdDate).toLocaleString()}</Typography>


                    </TimelineOppositeContent>

                    {/* Separator with icon and connector */}
                    <TimelineSeparator style={{float:'left', width: '20px', marginLeft: '10px'}}>
                        {/* <TimelineDot color={item.color || 'primary'}>
              {typeIcons[item.type] || <RepeatIcon />}
            </TimelineDot> */}
                        <TimelineDot />
                        <TimelineConnector />
                        {index < sortedData.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>

                    {/* Main content */}
                    <TimelineContent style={{ width: '70%',float:'left' , padding:0 }}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography color={item.color || 'primary'} variant="h6" component="h1" style={{ fontSize: '1rem', fontStyle: 'italic', fontWeight: '400' }}>
                                {item.title}
                            </Typography>
                            <Typography style={{ fontSize: '0.7rem' }}>{item.description}</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};



export default CustomizedTimeline;