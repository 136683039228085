import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags:  [],
      closeTagsPermission:props.closeTagsPermission,
      editTagsPermission:props.editTagsPermission,
      user:props.user

    };
    this.onTagsChange = this.onTagsChange.bind(this);
  }

  onTagsChange = (event, values,reason) => {

    console.log("permissions........",this.state.closeTagsPermission)

    if(this.state.closeTagsPermission != true && this.state.user && this.state.user.role == 'agent'){
       if(reason == 'remove-option'){
        alert("Campaign Don`t Have Permission to remove Tags");
        return true;
       }
    }

    this.setState({
      tags: values
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
      //console.log(this.state.tags);
      this.props && this.props.onTagsChange && this.props.onTagsChange(this.state.tags)
    });
  }

  render() {
    let selectedTag = [];
    debugger;
    if (this.state.tags && this.state.tags.length > 0) {

      this.state.tags.forEach(element => {
        selectedTag.push(element)
      });

    }
    else if (this.props.defaultValue && this.props.defaultValue.length > 0) {
      this.props.defaultValue.forEach(element => {
        selectedTag.push(element)
      });

    }


    return (

      <Autocomplete
        multiple
        options={this.props.options}
        getOptionLabel={option => option[this.props.OptionLabel]}
        value={selectedTag}
        onChange={this.onTagsChange}
        renderInput={params => (
          <>
            {console.log(params)}
            <TextField
              {...params}
              variant="standard"
              label={this.props.label}
              placeholder={this.props.placeholder}
              margin="normal"
              fullWidth
            />
          </>
        )}
      />

    );
  }
}